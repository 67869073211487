@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800&display=swap');

* {
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
  margin: 0px;
  padding: 0px;
  font-family: Inter;
}
html{
  width: 100%;
  overflow-x: hidden;
  background: #f8f8f8;
}

:root {
  --primary: #0C5398;
  /* --secondary:#154492; */
}

.spaceofx{
  width: 80% !important;
}

.headline{
  color: #0C5398;
  font-weight: 800;
  font-size: 30px;
}
.title20{
  font-size: 20px;
  font-weight: 600;
  color: #000;
}

.bigboss{
  /* border: 1px solid black; */
  box-shadow: none;
  transition: box-shadow 0.7s;
}

.bigboss:hover{
  box-shadow: 0px 4px 34px 0px rgba(12, 83, 152, 0.2);
}

.title2{
  font-size: 45px;
  font-weight: 600;
  line-height: 60px;
  color: #0C5398;
}

.sec-center {
  position: relative;
  max-width: 100%;
  text-align: center;
  z-index: 200;
}












.imgsize123{
  width: 47%;
}




.subcarttitle{
  /* border: 1px solid black; */
  padding: 6px 16px;
  border-radius: 26px;
  color: white;
  background: red;
}


.price-area229 {
  position: relative;
  padding: 10px 0;
  color: #ffff;
  font-weight: 300;
  font-size: 22px;
  line-height: 1;
}

.single-pricing {
  /* position: relative;
  text-align: center;
  background-color: #fff;
  color: #0070DC;
  margin: 0 10px;
  padding: 30px 0px;
  border-radius: 7px;
  border: 1px solid #0070DC;
  overflow: hidden; */



  position: relative;
  text-align: center;
  color: #0070DC;
  margin: 0 10px;
  padding: 30px 0px;
  /* border-radius: 7px; */
  border-radius: 55px 0px 55px 0px;
  /* border: 1px solid #0070DC; */
  /* overflow: hidden; */
  box-shadow: 4px 10px 12px 3px rgba(0, 0, 0, 0.6);
  background: linear-gradient(to bottom, rgba(121, 189, 255, 1), rgba(36, 129, 219, 1));
}
.translate-middle {
  transform: translate(-63%,-54%)!important;
}


.head-text {
  background-color: transparent;
  border-bottom: 0;
  padding: 15px 0;
  border-radius: 3px 3px 0 0;
}

.banner-refund {
  width: 100%;
  height: 100%;
  background: url(./assets/qwerty12.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  /* opacity: 0.9; */
}

.discount{
  color: #0070dc;
    border-radius: 3px;
    background: #ecf7ff;
    padding: 5px 15px;
    font-size: 14px;
    font-weight: 600;
}

.react-overlay129 {
  /* background-color: rgba(12, 83, 152, 0.6); */
  background-color: rgba(0, 0, 0, 0.41);
  overflow: hidden;
  opacity: 1.8;
  padding: 100px 10px;
  height: 488px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.banner-policy {
  width: 100%;
  height: 100%;
  background: url(./assets/Rectangle\ 179.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  /* opacity: 0.9; */
}
.head-text h3 {
  font-family: poppins;
  margin: 0;
  font-size: 20px;
  letter-spacing: 1px;
  font-weight: 700;
  text-transform: uppercase;
  color: #000;
}
.owlwidth{
  width: 100%;
}

.price-area .price-area span {
  font-weight: 600;
}

.price-area {
  /* position: relative;
  padding: 10px 0;
  color: white;
  font-weight: 300;
  font-size: 36px;
  line-height: 1; */
  display: flex;
  position: relative;
  padding: 10px 0;
  color: white;
  font-weight: 300;
  font-size: 16px;
  line-height: 1;
  justify-content: center;
  align-items: baseline;
  
}

.newjc{
  justify-content: center;
}


.price-area000123 {
  /* position: relative;
  padding: 10px 0;
  color: white;
  font-weight: 300;
  font-size: 36px;
  line-height: 1; */
  display: flex;
  position: relative;
  /* padding: 10px 0; */
  color: white;
  font-weight: 300;
  font-size: 16px;
  line-height: 1;
  justify-content: center;
  align-items: baseline;
  
}


.VIpgJd-ZVi9od-aZ2wEe-OiiCO-ti6hGc{
position: absolute;
top: -400px;
}
.price-area22 {
  position: relative;
  padding: 10px 0;
  color: #ffff;
  font-weight: 300;
  font-size: 41px;
  line-height: 1;
  
}

.price-area span.duration {
  /* display: block;
  font-size: 30px;
  font-weight: 600;

  margin: 10px 0 0;
  text-align: center;
  color: white;
  letter-spacing: 1px;
  vertical-align: baseline; */

  display: block;
    font-size: 22px;
    font-weight: 400;
    /* text-transform: uppercase; */
    margin: 10px 0 0;
    text-align: center;
    color: white;
    letter-spacing: 1px;
    vertical-align: baseline;
    /* margin: 20px 0 0 3px; */
    text-decoration: line-through;
    text-decoration-color: red;
    padding-right: 7px;

}







.normaltxt{
  font-weight: 500;
  color: red;
}
.price-area span.duration23 {
  display: block;
    font-size: 33px;
    font-weight: 600;
    /* text-transform: uppercase; */
    margin: 10px 0 0;
    text-align: center;
    color: red;
    letter-spacing: 1px;
    vertical-align: baseline;
    padding-right: 7px;

}


.center > div > .single-pricing{
  background: linear-gradient(to bottom, rgb(12, 83, 152), rgb(18, 113, 205));
  scale: 1.1;

}

.center > div > .single-pricing > .head-text h3{
  color: white;
}
.center > div > .single-pricing > .price-area{
  color: white;
}
.center > div > .single-pricing > .price-area span{
  color: white;
}
.center > div > .single-pricing > .btn-area a{
  color: #0070DC;
  background-color: white;
}














.single-pricing2{
  background: linear-gradient(to bottom, rgb(12, 83, 152), rgb(18, 113, 205));
  /* scale: 1.1; */

}

.single-pricing2 > .head-text h3{
  color: white;
}
.single-pricing2 > .price-area{
  color: white;
}
.single-pricing2 > .price-area span{
  color: white;
}
.single-pricing2 > .btn-area a{
  color: #0070DC;
  background-color: white;
}

















.center > div{
  width: 93%;
  scale: 1.1;
}

.footwidth{
  width: 100%;
}
.desc2031{
  margin: 0px;
  padding: 0px;
  font-size: 29px;
  font-weight: 700;
  color: rgba(12, 83, 152, 1);
}

.image865{
  background: white;
    width: fit-content;
    padding: 13px 4px;
    border-radius: 50%;
    overflow: hidden;
}


.input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  BACKGROUND: #243665;
  color: white;
}
#basic-addon2{
  background-color: #0c5398;
}

.desc1202{
  font-size: 19px;
  font-weight: 400;
  line-height: 34px;
  text-align: justify;
}
.backgroundbox123{
  position: absolute;
  top: 196px;
  height: 100%;
  width: 100%;
  padding: 10px;
  background: linear-gradient(to bottom, rgb(12, 83, 152), rgb(18, 113, 205));
}
.center .pop-label{
  display: block;
  position: absolute;
  width: 100%;
  top: 64px;
  right: -112px;
  transform: rotate(41deg);
  background-color: #ff0c00;
  color: #ffffff;
  padding: 1px 10px;
  font-weight: bold;
  font-size: 14px;
}

.imgbox{
  display: flex;
  justify-content: center;
}

.footer .social-foo {
  display: flex;
  align-items: center;
  justify-content: center;
  /* font-size: 20px; */
  /* padding-left: 0px; */
}
.footer ul li {
  list-style: none;
  padding: 10px;
  /* padding-top: 0px; */
  border: 1px solid #0C5398 ;
  border-radius: 50%;
}

#root > div > div > div > section.superpadding > form > div > div > div.col-md-7.col-12 > div > div:nth-child(4) > div > div:nth-child(2) > div > input{
  border: none;
  outline: none;
}

p{
  margin: 0px;
}

#root > div > div > div > section.superpadding > form > div > div > div.col-7 > div > div:nth-child(4) > div > div:nth-child(2) > div > input{
  border: none;
  outline: none;
}
.contact ul li {
  list-style: none;
  padding: 10px;
  /* padding-top: 0px; */
  border: 1px solid #0C5398 ;
  border-radius: 50%;
}
.hideul{
  display: flex;
}
.social-foo{
  margin: 0px;
  gap: 10px;
  color: rgba(12, 83, 152, 1);
}
.social-foo a{
  color: rgba(12, 83, 152, 1);
}

.desc203{
  font-weight: 700;
  font-size: 46px;
  line-height: 56.07px;
  z-index: 5;
  color: white;
  margin: 0px 30px;
}
.backimgregister{
  position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
}
.registerdesc{
  width: 100%;
    height: 500px;
    overflow: hidden;
  position: relative !important;
  display: flex;
  justify-content: baseline;
  align-items: center;

}
.social-foo a i {
  height: 34px;
  width: 34px;
  line-height: 12px;
  color: #E95F21;
  text-align: center;
  font-size: 16px;
  padding: 10px;
  /* background-color: #1A76D1; */
  border-radius: 100%;
  border: 1px solid #E95F21;
}

.headline234{
  display: flex;
    justify-content: center;
    margin: 0px;
    padding: 0px;
    font-size: 20px;
    line-height: 32px;
    font-weight: 600;
    color: rgba(12, 83, 152, 1);
}

.pop-label{
  display: none;
  position: absolute;
  width: 100%;
  top: 50px;
  right: -101px;
  transform: rotate(41deg);
  background-color: #ff0c00;
  color: #ffffff;
  padding: 1px 10px;
  font-weight: bold;
  font-size: 14px;
}
#root > div > div > div > div:nth-child(2) > section > div > form > div > div:nth-child(4) > div > div.col-lg-9 > div > input{
  border: none;
  outline: none;
}

.specialinderline0002{
  height: 100%;
    width: 208px;
    background: red;
    padding: 0px;
    margin: 0px;
    position: absolute;
    left: 0px;
    top: 0px;
}
.specialinderline00022{
  height: 100%;
    width: 170px;
    background: red;
    padding: 0px;
    margin: 0px;
    position: absolute;
    left: 0px;
    top: 0px;
}

.price-area:after, .feature-area{
  border: 0;
  font-family: poppins;
  
}

.extrapadding{
  padding-bottom: 41px !important;
  padding-top: 0.5rem !important;
}

.feature-area ul {
  margin: 0;
  padding: 15px 0;
  list-style: none;
 }

.feature-area li {
  padding: 6px 0;
  font-size: 14px;
}

.btn-area {
  padding: 15px 30px;
}

.btn-area a {
  color: rgba(12, 83, 152, 1);
  display: inline-block;
  position: relative;
  cursor: pointer;
  outline: 0;
  white-space: nowrap;
  margin: 5px;
  padding: 0 22px;
  font-size: 14px;
  height: 40px;
  line-height: 40px;
  background-color:rgba(234, 234, 234, 1);
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  border: none;
}



.btn-areabtn {
  color: rgba(12, 83, 152, 1);
  display: inline-block;
  position: relative;
  cursor: pointer;
  outline: 0;
  white-space: nowrap;
  margin: 5px;
  padding: 0 22px;
  font-size: 14px;
  height: 40px;
  line-height: 40px;
  background-color:rgba(234, 234, 234, 1);
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  border: none;
}

.label-area {
  width: 80px;
  height: 80px;
  position: absolute;
  top: -1px;
  right: -1px;
  text-align: center;
  border-top-right-radius: 15px;
  background: #ef4368;
}

.label-area span {
  position: absolute;
  top: 22px;
  right: 3px;
  text-transform: uppercase;
  transform: rotate(45deg);
  font-weight: 700;
  font-size: 10px;
  color: #fff;
  letter-spacing: 1px;
}


a{
  text-decoration: none !important;
}














































.comm-head p {
  text-decoration: underline;
  font-size: 18px;
  font-weight: 700;
}

.royal-head {
  font-size: 50px;
  text-align: center;
  color: black;
  font-family: Montserrat;

}

.royal-head span {
  font-size: 50px;
  color: #0070DC;
  font-family: "Montserrat";
}

.royal-p {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  font-family: Montserrat;
}


.desc23{
  font-size: 20px;
  line-height: 24px;
}

.forheading{
  font-size: 45px;
  font-weight: 600;
  line-height: 60px;
  color: white;
}

.fordesc{
  font-size: 30px;
  color: white;
  font-weight: 500;
  line-height: 36.57px;
}
.backgroundbox{
  position: absolute;
  height: 400px;
  width: 100%;
  padding: 10px;
  background: linear-gradient(to bottom, rgb(12, 83, 152), rgb(18, 113, 205));
}


.royal-left {
  background: #0070DC;
  height: 393px;
  margin-top: 142px;
}

.royal-right {
  background: #0070DC;
  height: 393px;
  margin-top: 142px;
}

.royal-sec .image p {
  display: none;
}
/* .royal-sec .image:hover p {
  display: block;
} */
.royal-sec .image:hover .over-royal {
  text-align: center;
  /* background-color: rgba(0, 0, 0, 0.5); */
  /* background-blend-mode: multiply;
  color: #fff;
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: .7; */
}

.imagecontainer {
position: relative;
display: inline-block;
z-index: 455;
}

.imagecontainer::before {
content: '';
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: rgba(0, 0, 0, 0.5); /* Adjust the opacity and color as needed */
opacity: 0;
transition: opacity 0.3s ease;
}

.imagecontainer:hover::before {
opacity: 1;
}
.show1twis:hover .imagecontainer::before{
opacity: 1;
}
.show1again:hover .imagecontainer::before{
opacity: 1;
}

.imagecontainer:hover .royal-sec .image p {
display: block;
z-index: 4556;
}

.up-project {
  width: auto;
  height: auto;
  transition: transform .5s;
}
#root > div > div > div > div:nth-child(2) > section > div > form > div > div:nth-child(22) > div > div.col-lg-9 > div > input{
  border: none;
  outline: none;
}
#root > div > div > div > div:nth-child(2) > section > div > form > div > div:nth-child(9) > div > div.col-lg-9 > div > input{
  border: none;
  outline: none;
}

.up-project:hover  {
  transform: scale(1.1);
}

.box-1 {
padding: 60px 40px;
}
/* .card-img-overlay {
position: absolute;
background: rgba(30, 30, 30, 0.7);
top: 0;
right: 0;
bottom: 0;
left: 0;
padding: 1rem;
border-radius: calc(.25rem - 1px)
} */
.manu-card{
margin-top: 9.5rem;
text-align: center;
font-size: 38px;
font-family: "Montserrat";
}





































#\:2\.container{
  display: none;
}
#root > div > div > div > div:nth-child(2) > div > div.container-fuild.pb-4.pt-3.px-2.bg-white > form > div.row.bg-white.pb-4.round > div:nth-child(6) > div > div.col-lg-9 > div > input{
  border: none;
    outline: none;
}
body {
  top: 0px !important; 
  }

  #root > div > div > div{
    overflow:hidden;
  }

  #\:0\.targetLanguage > img{
    display: none;
  }
#root > div > div > div > div.header > div:nth-child(2) > div.icongroup > div:nth-child(2) > video{
  width: 2px !important;
  height: 7px !important;
  background: #0f64b6;
  position: absolute;
  top: -45455px;
}
#root > div > div > div > div > div.dashboardcontent.px-2 > div.container-fuild.pb-4.pt-3.px-3.bg-white > form > div.row.bg-white.pb-4.round > div:nth-child(13) > div > div.col-md-6 > div > div:nth-child(3) > div.border.round.py-3.d-flex.justify-content-center > label > div > div:nth-child(3){
  width: 300px !important;
  height: 400px !important;
  /* right: 100% !important; */
  left: -222% !important;
}
#root > div > div > div > section:nth-child(2) > div > form > div > div:nth-child(9) > div > div.col-lg-9 > div > input{
  border: none;
  outline: none;
}


#root > div > div > div > section:nth-child(2) > div > form > div > div:nth-child(22) > div > div.col-lg-9 > div > input{
  border: none;
  outline: none;
}
#root > div > div > div > div > div.dashboardcontent.px-2 > div.container-fuild.pb-4.pt-3.px-2.bg-white > form > div.row.bg-white.pb-4.round > div:nth-child(18) > div > div:nth-child(2) > div > div:nth-child(2) > div.border.round.py-3.d-flex.justify-content-center > label > div > div:nth-child(3){
  width: 300px !important;
  height: 400px !important;
  /* right: 100% !important; */
  left: -222% !important;
}
#root > div > div > div > div > div.dashboardcontent.px-2 > div.container-fuild.pb-4.pt-3.px-2.bg-white > form:nth-child(3) > div > div:nth-child(15) > div > div:nth-child(2) > div > div:nth-child(2) > div > div > div:nth-child(3){
  width: 300px !important;
  height: 400px !important;
  /* right: 100% !important; */
  left: -222% !important;
}
#root > div > div > div > div > div.dashboardcontent.px-2 > div.container-fuild.pb-4.pt-3.px-2.bg-white > form:nth-child(3) > div > div:nth-child(16) > div > div:nth-child(7) > div.row.px-2.pt-3 > div:nth-child(2) > div > div:nth-child(2) > div.border.round.py-3.d-flex.justify-content-center > label > div > div{
    width: 300px !important;
  height: 400px !important;
  /* right: 100% !important; */
  left: -222% !important;
}
#root > div > div > div > div > div.dashboardcontent.px-2 > div.container-fuild.pb-4.pt-3.px-2.bg-white > form > div > div:nth-child(15) > div > div:nth-child(2) > div > div:nth-child(2) > div > div > div:nth-child(3){
  width: 300px !important;
  height: 400px !important;
  /* right: 100% !important; */
  left: -222% !important;
}
#root > div > div > div > div > div.dashboardcontent.px-2 > form > div > div.row.bg-white.pb-4.round > div:nth-child(18) > div > div:nth-child(2) > div > div:nth-child(2) > div > div > div:nth-child(1) > div > div:nth-child(3){
  width: 300px !important;
  height: 400px !important;
  /* right: 100% !important; */
  left: -222% !important;
}
.dropdown-menu {
  position: absolute;
  width: 25vw !important;
  inset: 0px auto auto 0px;
  margin: 0px;
  padding: 8px 10px;
  transform: translate(-200px, 40px) !important;
}


.react-overlay {
  background-color: rgba(47, 44, 44, 0.5);
  overflow: hidden;
  opacity: 1.8;
  padding: 100px 10px;
  height: 488px;

}
.react-overlaynew {
  background-color: rgba(47, 44, 44, 0.5);

}


#root > div > div > div > section.banner-jaipur > div.react-overlaynew.bannersec > div > div.owl-stage-outer{
  width: 100%;
  height: 100%;
}
.banner-jaipur {
  position: relative;
  width: 100%;
  height: 100%;
  /* background: url(./assets/123.png); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  /* opacity: 0.9; */
}


.banner-term {
  width: 100%;
  height: 100%;
  background: url(./assets/qwerty12.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  /* opacity: 0.9; */
}


.banner-about {
  width: 100%;
  height: 100%;
  background: url(./assets/Mask\ group.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  align-items: center;
  /* opacity: 0.9; */
}


.backabout{
  position: absolute;
    top: 94px;
    left: 0px;
    width: 100%;
    height: 70%;
    background: red;
}



.banner-contact {
  width: 100%;
  height: 100%;
  background: url(./assets/Rectangle\ 280.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  /* opacity: 0.9; */
}


.desc120{
  font-size: 23px;
  font-weight: 400;
  line-height: 35px;
  text-align: justify;
  color: rgba(75, 75, 75, 1);
}

.allowlist{
  list-style: disc !important;
  margin: 0px
}

.title120{
  font-weight: 700;
  font-size: 30px;
  line-height: 40px;
  text-align: justify;
  color: rgba(12, 83, 152, 1);
}

.react-overlay12 {
  background-color: rgba(12, 83, 152, 0.6);
  overflow: hidden;
  opacity: 1.8;
  padding: 100px 10px;
  height: 488px;
display: flex;
justify-content: center;
align-items: center;
}

.react-overlay121 {
  /* background-color: rgba(12, 83, 152, 0.6); */
  overflow: hidden;
  opacity: 1.8;
  padding: 100px 10px;
  height: 356px;
display: flex;
justify-content: center;
align-items: center;
}
.desc345{
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: rgba(36, 54, 101, 1);
}

#root > div > div > div > section.superpadding > div > form > div > div.col-7 > div > div:nth-child(4) > div > div:nth-child(2) > div > input{
  border: none;
  outline: none;
}
.react-overlay125 {
  /* background-color: rgba(12, 83, 152, 0.6); */
  overflow: hidden;
  opacity: 1.8;
  padding: 31px 0px 276px 10px;
  /* height: 100%; */
display: flex;
justify-content: center;
align-items: center;
}
.page-title{
  font-size: 44px;
}

.bann-txt{
  color: #fff;
  text-align: center;
  font-weight: 800;
  letter-spacing: 3px;
}
#root > div > div > div > section:nth-child(9) > div > div:nth-child(2) > div.col-6 > form > div > div.col-md-6.px-2.pt-4 > div > div:nth-child(2) > div > input{
  border: none;
  outline: none;
}
.PhoneInputInput{
  border: none !important;
  outline: none !important;
}

.imgsize{
  width: 47%;
}
.imgdivsize{
  width: auto !important;
}
#root > div > div > div > div > div.dashboardcontent.px-2 > form > div > div.row.bg-white.pb-4.round > div:nth-child(20) > div > div:nth-child(2) > div > div:nth-child(2) > div > div > div:nth-child(2) > div > div:nth-child(3){
  width: 300px !important;
  height: 400px !important;
  /* right: 100% !important; */
  left: -222% !important;
}
#root > div > div > div > div > div.dashboardcontent.px-2 > form > div > div.row.bg-white.pb-4.round > div:nth-child(15) > div > div:nth-child(2) > div > div:nth-child(2) > div > div > div:nth-child(3){
  width: 300px !important;
  height: 400px !important;
  /* right: 100% !important; */
  left: -222% !important;
}
#root > div > div > div > div > div.dashboardcontent.px-2 > div.container-fuild.pb-4.pt-3.px-2.bg-white > div > div:nth-child(22) > div > div:nth-child(2) > div > div:nth-child(2) > div > div > div:nth-child(1) > div > div:nth-child(3){
  width: 300px !important;
  height: 400px !important;
  /* right: 100% !important; */
  left: -222% !important;
}
#root > div > div > div > div > div.dashboardcontent.px-2 > div.container-fuild.px-2.pb-4.pt-4.bg-white > div > div:nth-child(25) > div > div:nth-child(2) > div > div:nth-child(2) > div > div > div:nth-child(1) > div > div:nth-child(3){
  width: 300px !important;
  height: 400px !important;
  /* right: 100% !important; */
  left: -222% !important;
}
#root > div > div > div > div > div.dashboardcontent.px-2 > div.container-fuild.pb-4.pt-3.px-2.bg-white > div > div:nth-child(17) > div > div:nth-child(13) > div > div:nth-child(2) > div > div:nth-child(2) > div > div > img{
  width: 140px !important;
    height: 162px !important;
}
#root > div > div > div > div > div.dashboardcontent.px-2 > form > div > div.row.bg-white.pb-4.round > div:nth-child(16) > div > div:nth-child(2) > div > div:nth-child(2) > div > div > div > div > div:nth-child(3){
  width: 300px !important;
  height: 400px !important;
  /* right: 100% !important; */
  left: -222% !important;
}
#root > div > div > div > div > div.dashboardcontent.px-2 > div.container-fuild.pb-4.pt-3.px-2.bg-white > div > div:nth-child(17) > div > div:nth-child(13) > div > div:nth-child(2) > div > div:nth-child(2) > div > div > div:nth-child(3) > img{
  width: 300px !important;
  height: 400px !important;
  /* right: 100% !important; */
  left: -222% !important;
}

#root > div > div > div > div > div.dashboardcontent.px-2 > div.container-fuild.pb-4.pt-3.px-2.bg-white > div > div:nth-child(17) > div > div:nth-child(12) > div > div:nth-child(2) > div > div:nth-child(2) > div > div > div:nth-child(3){
  width: 300px !important;
  height: 400px !important;
  /* right: 100% !important; */
  left: -222% !important;
}
#root > div > div > div > div > div.dashboardcontent.px-2 > div.container-fuild.pb-4.pt-3.px-2.bg-white > div > div:nth-child(17) > div > div:nth-child(13) > div > div:nth-child(2) > div > div:nth-child(2) > div > div > div:nth-child(3){
  width: 300px !important;
  height: 400px !important;
  /* right: 100% !important; */
  left: -222% !important;
}
#root > div > div > div > div > div.dashboardcontent.px-2 > form > div > div.row.bg-white.pb-4.round > div:nth-child(16) > div > div:nth-child(7) > div > div:nth-child(2) > div > div:nth-child(2) > div > div > div:nth-child(3){
  width: 300px !important;
  height: 400px !important;
  /* right: 100% !important; */
  left: -222% !important;
}
#root > div > div > div > div > div.dashboardcontent.px-2 > div.container-fuild.px-2.pb-4.pt-4.bg-white > div > div:nth-child(25) > div > div:nth-child(2) > div > div:nth-child(2) > div > div > div:nth-child(2) > div > div:nth-child(3){
  width: 300px !important;
  height: 400px !important;
  /* right: 100% !important; */
  left: -222% !important;
}
#root > div > div > div > div > div.dashboardcontent.px-2 > div.container-fuild.pb-4.pt-3.px-2.bg-white > div > div:nth-child(22) > div > div:nth-child(2) > div > div:nth-child(2) > div > div > div:nth-child(2) > div > div:nth-child(3){
  width: 300px !important;
  height: 400px !important;
  /* right: 100% !important; */
  left: -222% !important;
}
#root > div > div > div > div > div.dashboardcontent.px-2 > form > div > div.row.bg-white.pb-4.round > div:nth-child(16) > div > div:nth-child(8) > div > div:nth-child(2) > div > div:nth-child(2) > div > div > div:nth-child(3){
  width: 300px !important;
  height: 400px !important;
  /* right: 100% !important; */
  left: -222% !important;
}
#root > div > div > div > div > div.dashboardcontent.px-2 > form > div > div.row.bg-white.pb-4.round > div:nth-child(20) > div > div:nth-child(2) > div > div:nth-child(2) > div > div > div:nth-child(1) > div > div:nth-child(3){
  width: 300px !important;
  height: 400px !important;
  /* right: 100% !important; */
  left: -222% !important;
}
#root > div > div > div > div > div.dashboardcontent.px-2 > form > div > div.row.bg-white.pb-4.round > div:nth-child(18) > div > div:nth-child(2) > div > div:nth-child(2) > div > div > div:nth-child(2) > div > div:nth-child(3){
  width: 300px !important;
  height: 400px !important;
  /* right: 100% !important; */
  left: -222% !important;
}
#root > div > div > div > div > div.dashboardcontent.px-2 > div.container-fuild.pb-4.pt-3.px-2.bg-white > div > div:nth-child(17) > div > div:nth-child(11) > div > div:nth-child(2) > div > div:nth-child(2) > div > div > div:nth-child(3){
  width: 300px !important;
  height: 400px !important;
  /* right: 100% !important; */
  left: -222% !important;
}
#root > div > div > div > div > div.dashboardcontent.px-2 > div.container-fuild.pb-4.pt-3.px-2.bg-white > form > div > div:nth-child(16) > div > div:nth-child(7) > div.row.px-2.pt-3 > div:nth-child(2) > div > div:nth-child(2) > div.border.round.py-3.d-flex.justify-content-center > label > div > div:nth-child(3){
  width: 300px !important;
  height: 400px !important;
  /* right: 100% !important; */
  left: -222% !important; 
}
#root > div > div > div > div > div.dashboardcontent.px-2 > div.container-fuild.pb-4.pt-4.px-2.bg-white > div > div:nth-child(23) > div > div:nth-child(2) > div > div:nth-child(2) > div > div > div:nth-child(3){
  width: 300px !important;
  height: 400px !important;
  /* right: 100% !important; */
  left: -222% !important;
}
#root > div > div > div > div > div.dashboardcontent.px-2 > div.container-fuild.pb-4.pt-3.px-2.bg-white > div > div.col-12 > div > div:nth-child(2) > div > div:nth-child(2) > div > div > div:nth-child(3){
  width: 300px !important;
  height: 400px !important;
  /* right: 100% !important; */
  left: -222% !important;
}

#root > div > div > div > div > div.dashboardcontent.px-2 > div.container-fuild.pb-4.pt-3.px-2.bg-white > form > div.row.bg-white.pb-4.round > div:nth-child(13) > div > div:nth-child(2) > div > div:nth-child(2) > div.border.round.py-3.d-flex.justify-content-center > label > div > div:nth-child(3){
  width: 300px !important;
  height: 400px !important;
  /* right: 100% !important; */
  left: -222% !important;
}
.image-magnify-container{
  width: 400px;
  height: 400px;
}
#myTable_wrapper > div.dt-buttons > button.dt-button.buttons-csv.buttons-html5{
  background-color: #EB011C !important;
  color: #fff;
  padding: 3px 10px;
  border: 1px solid #EB011C;
  outline: none;
  border-radius: 2px;

}
#myTable_wrapper > div.dt-buttons{
  position: relative;
  top: 29px;
}
#myTable_wrapper > div.dt-buttons > button.dt-button.buttons-csv.buttons-html5 > span, 
#myTable_wrapper > div.dt-buttons > button.dt-button.buttons-print > span{
  font-size: 13px;
}
#myTable_wrapper > div.dt-buttons{
  display: inline !important;
}

#myTable_wrapper > div.dt-buttons > button.dt-button.buttons-print{
  background-color: #EB011C !important;
  color: #fff;
  padding: 3px 10px;
  border: 1px solid #EB011C;
  outline: none;
  border-radius: 2px;

}

#dropdown[type="checkbox"]:checked,
#dropdown[type="checkbox"]:not(:checked) {
  position: absolute;
  left: -9999px;
  opacity: 0;
  pointer-events: none;
}

.dark-light:checked+label,
.dark-light:not(:checked)+label {
  position: fixed;
  top: 40px;
  right: 40px;
  z-index: 20000;
  display: block;
  border-radius: 50%;
  width: 46px;
  height: 46px;
  cursor: pointer;
  transition: all 200ms linear;
  box-shadow: 0 0 25px rgba(255, 235, 167, .45);
}

.desin{
      /* border: 1px solid rgb(224, 224, 224); */
      margin: 0px;
      /* border-radius: 3px; */
      /* padding-bottom: 3rem; */
      padding: 24px 20px;
  width: 20%;
  height: 23%;
  /* box-shadow: 16px 14px 20px #0000008c; */
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.desin::before{
  content: "dd";
  color: transparent;
  background-image: conic-gradient( #ff0052 20deg, transparent 120deg );
  width: 150%;
  height: 150%;
  position: absolute;
  animation: rotate 2s linear infinite;
}
.desin::after{
  content: "dd";
  width: 67px;
  height: 44px;
  color: #f8f8f8;
  background: #f8f8f8;
  position: absolute;
  border-radius: 9px;
  border: 1px solid rgb(224, 224, 224);
}

.normaldes{
  border: 1px solid #E0E0E0;
  margin: 0px 0px;
   border-radius: 3px;
   width:20%;
   /* padding-bottom: 3rem; */
}
@keyframes rotate {
  0%{
      transform: rotate(0deg);
  }
  100%{
      transform: rotate(-360deg);
  }
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #EB011C !important;
  border-color: #EB011C !important;
}
.dataTable {
  clear: both;
  /* margin-top: 6px !important; */
  /* margin-bottom: 6px !important; */
  max-width: none !important;
  border-collapse: separate !important;
  border-spacing: 0;
  width: 100% !important;
  margin: 0px -37px 0px 0px !important;
}

.hideserchbar #myTable_filter > label{
  display: none;
}
.dataTables_filter{
  text-align: right !important;
}
.customewidth{
   width: 140px !important;
  height: 162px !important;
}
#root > div > div > div > div > div.dashboardcontent.px-2 > div.container-fuild.pb-4.pt-3.px-2.bg-white > form:nth-child(3) > div > div:nth-child(15) > div > div:nth-child(9) > div.row.px-2.pt-3 > div:nth-child(2) > div > div:nth-child(2) > div.border.round.py-3.d-flex.justify-content-center > label > div > img{
  width: 140px !important;
  height: 162px !important;
}
#root > div > div > div > div > div.dashboardcontent.px-2 > div.container-fuild.pb-4.pt-3.px-2.bg-white > form > div.row.bg-white.pb-4.round > div:nth-child(17) > div > div:nth-child(2) > div > div:nth-child(2) > div.border.round.py-3.d-flex.justify-content-center > label > div > img{
  width: 140px !important;
  height: 162px !important;
}
#root > div > div > div > div > div.dashboardcontent.px-2 > div.container-fuild.pb-4.pt-3.px-2.bg-white > div > div:nth-child(17) > div > div:nth-child(13) > div > div:nth-child(2) > div > div:nth-child(2) > div > div > div >img{
  width: 140px !important;
  height: 162px !important;
}
#root > div > div > div > div > div.dashboardcontent.px-2 > form > div > div.row.bg-white.pb-4.round > div:nth-child(17) > div > div:nth-child(2) > div > div:nth-child(2) > div > div > img{
  width: 140px !important;
  height: 162px !important;
}
#root > div > div > div > div > div.dashboardcontent.px-2 > form > div > div.row.bg-white.pb-4.round > div:nth-child(18) > div > div:nth-child(2) > div > div:nth-child(2) > div > div > img{
  width: 140px !important;
  height: 162px !important;
}
#root > div > div > div > div > div.dashboardcontent.px-2 > div.container-fuild.pb-4.pt-3.px-2.bg-white > div > div:nth-child(17) > div > div:nth-child(13) > div > div:nth-child(2) > div > div:nth-child(2) > div > div > div > div:nth-child(3){
  width: 300px !important;
  height: 400px !important;
  /* right: 100% !important; */
  left: -222% !important;
}
#root > div > div > div > div > div.dashboardcontent.px-2 > form > div > div.row.bg-white.pb-4.round > div:nth-child(18) > div > div:nth-child(2) > div > div:nth-child(2) > div > div > div:nth-child(1) > div > img{
  width: 140px !important;
  height: 162px !important;
}
#root > div > div > div > div > div.dashboardcontent.px-2 > form > div > div.row.bg-white.pb-4.round > div:nth-child(20) > div > div:nth-child(2) > div > div:nth-child(2) > div > div > div:nth-child(2) > div > img{
  width: 140px !important;
  height: 162px !important; 
}
#root > div > div > div > div > div.dashboardcontent.px-2 > div.container-fuild.pb-4.pt-3.px-2.bg-white > form > div.row.bg-white.pb-4.round > div:nth-child(14) > div > div:nth-child(2) > div > div:nth-child(2) > div.border.round.py-3.d-flex.justify-content-center > label > div > img{
  width: 140px !important;
  height: 162px !important; 
}
#root > div > div > div > div > div.dashboardcontent.px-2 > div.container-fuild.pb-4.pt-3.px-2.bg-white > div > div:nth-child(22) > div > div:nth-child(2) > div > div:nth-child(2) > div > div > div:nth-child(1) > div > img{
  width: 140px !important;
  height: 162px !important;
}
#root > div > div > div > div > div.dashboardcontent.px-2 > div.container-fuild.pb-4.pt-3.px-2.bg-white > div > div:nth-child(22) > div > div:nth-child(2) > div > div:nth-child(2) > div > div > div:nth-child(2) > div > img{
  width: 140px !important;
  height: 162px !important;
}
#root > div > div > div > div > div.dashboardcontent.px-2 > div.container-fuild.px-2.pb-4.pt-4.bg-white > div > div:nth-child(25) > div > div:nth-child(2) > div > div:nth-child(2) > div > div > div:nth-child(1) > div > img{
  width: 140px !important;
  height: 162px !important;
}
#root > div > div > div > div > div.dashboardcontent.px-2 > div.container-fuild.px-2.pb-4.pt-4.bg-white > div > div:nth-child(25) > div > div:nth-child(2) > div > div:nth-child(2) > div > div > div:nth-child(2) > div > img{
  width: 140px !important;
  height: 162px !important;
}
#root > div > div > div > div > div.dashboardcontent.px-2 > div.container-fuild.pb-4.pt-4.px-2.bg-white > div > div:nth-child(23) > div > div:nth-child(2) > div > div:nth-child(2) > div > div > img{
  width: 140px !important;
  height: 162px !important; 
}
#root > div > div > div > div > div.dashboardcontent.px-2 > div.container-fuild.pb-4.pt-3.px-2.bg-white > form > div.row.bg-white.pb-4.round > div:nth-child(18) > div > div:nth-child(2) > div > div:nth-child(2) > div.border.round.py-3.d-flex.justify-content-center > label > div > img{
  width: 140px !important;
  height: 162px !important; 
}
#root > div > div > div > div > div.dashboardcontent.px-2 > div.container-fuild.pb-4.pt-3.px-3.bg-white > form > div.row.bg-white.pb-4.round > div:nth-child(13) > div > div.col-md-6 > div > div:nth-child(3) > div.border.round.py-3.d-flex.justify-content-center > label > div > img{
  width: 140px !important;
  height: 162px !important; 
}
#root > div > div > div > div > div.dashboardcontent.px-2 > form > div > div.row.bg-white.pb-4.round > div:nth-child(20) > div > div:nth-child(2) > div > div:nth-child(2) > div > div > div:nth-child(1) > div > img{
  width: 140px !important;
  height: 162px !important;
}
#root > div > div > div > div > div.dashboardcontent.px-2 > form > div > div.row.bg-white.pb-4.round > div:nth-child(16) > div > div:nth-child(2) > div > div:nth-child(2) > div > div > div > div > img{
  width: 140px !important;
  height: 162px !important;
}
#root > div > div > div > div > div.dashboardcontent.px-2 > form > div > div.row.bg-white.pb-4.round > div:nth-child(18) > div > div:nth-child(2) > div > div:nth-child(2) > div > div > div:nth-child(2) > div > img{
  width: 140px !important;
  height: 162px !important;
}
#root > div > div > div > div > div.dashboardcontent.px-2 > div.container-fuild.pb-4.pt-3.px-2.bg-white > div > div:nth-child(16) > div > div:nth-child(2) > div > div:nth-child(2) > div > div > img{
  width: 140px !important;
  height: 162px !important;
}
#root > div > div > div > div > div.dashboardcontent.px-2 > div.container-fuild.pb-4.pt-3.px-2.bg-white > div > div:nth-child(17) > div > div:nth-child(12) > div > div:nth-child(2) > div > div:nth-child(2) > div > div > img{
  width: 140px !important;
  height: 162px !important;
}
#root > div > div > div > div > div.dashboardcontent.px-2 > form > div > div.row.bg-white.pb-4.round > div:nth-child(15) > div > div:nth-child(12) > div > div:nth-child(2) > div > div:nth-child(2) > div > div > img{
  width: 140px !important;
  height: 162px !important;
}
#root > div > div > div > div > div.dashboardcontent.px-2 > form > div > div.row.bg-white.pb-4.round > div:nth-child(16) > div > div:nth-child(7) > div > div:nth-child(2) > div > div:nth-child(2) > div > div > img{
  width: 140px !important;
  height: 162px !important;
}
#root > div > div > div > div > div.dashboardcontent.px-2 > form > div > div.row.bg-white.pb-4.round > div:nth-child(16) > div > div:nth-child(8) > div > div:nth-child(2) > div > div:nth-child(2) > div > div > img{
  width: 140px !important;
  height: 162px !important;
}
#root > div > div > div > div > div.dashboardcontent.px-2 > div.container-fuild.pb-4.pt-3.px-2.bg-white > div > div:nth-child(16) > div > div:nth-child(11) > div > div:nth-child(2) > div > div:nth-child(2) > div > div > img{
  width: 140px !important;
  height: 162px !important;
}
#root > div > div > div > div > div.dashboardcontent.px-2 > div.container-fuild.pb-4.pt-3.px-2.bg-white > div > div:nth-child(15) > div > div:nth-child(2) > div > div:nth-child(2) > div > div > img{
  width: 140px !important;
  height: 162px !important;
}
#root > div > div > div > div > div.dashboardcontent.px-2 > div.container-fuild.pb-4.pt-3.px-2.bg-white > div > div:nth-child(16) > div > div:nth-child(12) > div > div:nth-child(2) > div > div:nth-child(2) > div > div > img{
  width: 140px !important;
  height: 162px !important;
}
#root > div > div > div > div > div.dashboardcontent.px-2 > div.container-fuild.pb-4.pt-3.px-2.bg-white > div > div:nth-child(19) > div > div:nth-child(2) > div > div:nth-child(2) > div > div > img{
  width: 140px !important;
  height: 162px !important;
}
#root > div > div > div > div > div.dashboardcontent.px-2 > div.container-fuild.px-2.pb-4.pt-4.bg-white > div > div:nth-child(22) > div > div:nth-child(2) > div > div:nth-child(2) > div > div > img{
  width: 140px !important;
  height: 162px !important;
}
#root > div > div > div > div > div.dashboardcontent.px-2 > form > div > div.row.bg-white.pb-4.round > div:nth-child(15) > div > div:nth-child(6) > div > div:nth-child(2) > div > div > img{
  width: 140px !important;
  height: 162px !important;
}
#root > div > div > div > div > div.dashboardcontent.px-2 > div.container-fuild.pb-4.pt-3.px-2.bg-white > form > div > div:nth-child(15) > div > div:nth-child(7) > div.row.px-2.pt-3 > div:nth-child(2) > div > div:nth-child(2) > div.border.round.py-3.d-flex.justify-content-center > label > div > img{
  width: 140px !important;
  height: 162px !important;
}
#root > div > div > div > div > div.dashboardcontent.px-2 > form > div > div.row.bg-white.pb-4.round > div:nth-child(16) > div > div:nth-child(2) > div > div:nth-child(2) > div > div > img{
  width: 140px !important;
  height: 162px !important;
}
#root > div > div > div > div > div.dashboardcontent.px-2 > div.container-fuild.pb-4.pt-3.px-2.bg-white > div > div:nth-child(15) > div > div:nth-child(13) > div > div:nth-child(2) > div > div:nth-child(2) > div > div > img{
  width: 140px !important;
  height: 162px !important;
}

#root > div > div > div > div > div.dashboardcontent.px-2 > div.container-fuild.pb-4.pt-3.px-2.bg-white > form > div > div:nth-child(15) > div > div:nth-child(9) > div.row.px-2.pt-3 > div:nth-child(2) > div > div:nth-child(2) > div.border.round.py-3.d-flex.justify-content-center > label > div > img{
  width: 140px !important;
  height: 162px !important;
}
video{
  width: 100%;
}

/* #root > div > div > aside > div > div > nav:nth-child(2) > ul > li:nth-child(8) > div > ul > a:nth-child(1) > li > a > span{
  padding-left: 62px;
}
#root > div > div > aside > div > div > nav:nth-child(2) > ul > li:nth-child(8) > div > ul > a.nav.active > li > a > span{
  padding-left: 62px;
}
#root > div > div > aside > div > div > nav:nth-child(2) > ul > li.ps-menuitem-root.ps-submenu-root.ps-open.css-16jesut > div > ul > a:nth-child(2) > li > a > span{
  padding-left: 62px;
}
#root > div > div > aside > div > div > nav:nth-child(2) > ul > li.ps-menuitem-root.ps-submenu-root.ps-open.css-16jesut > div > ul > a:nth-child(3) > li > a > span{
  padding-left: 62px;
}
#root > div > div > aside > div > div > nav:nth-child(2) > ul > li:nth-child(9) > div > ul > a:nth-child(1) > li > a > span{
  padding-left: 62px;
}
#root > div > div > aside > div > div > nav:nth-child(2) > ul > li > div > ul > a.nav.active > li > a > span{
  padding-left: 62px;
}
#root > div > div > aside > div > div > nav:nth-child(2) > ul > li > div > ul > a:nth-child(1) > li > a > span{
  padding-left: 62px;
} */
#root > div > div > div > div > div.dashboardcontent.px-2 > div.container-fuild.pb-4.pt-3.px-2.bg-white > form > div.row.bg-white.pb-4.round > div:nth-child(16) > div > div:nth-child(2) > div > div:nth-child(2) > div.border.round.py-3.d-flex.justify-content-center > label > div > img{
  width: 140px !important;
  height: 162px !important;
}
#root > div > div > div > div > div.dashboardcontent.px-2 > div.container-fuild.pb-4.pt-4.px-2.bg-white > div > div:nth-child(20) > div > div:nth-child(2) > div > div:nth-child(2) > div > div > img{
  width: 140px !important;
  height: 162px !important;
}
#root > div > div > div > div > div.dashboardcontent.px-2 > div.container-fuild.pb-4.pt-3.px-3.bg-white > form > div.row.bg-white.pb-4.round > div:nth-child(12) > div > div.col-md-6 > div > div:nth-child(2) > div.border.round.py-3.d-flex.justify-content-center > label > div > img{
  width: 140px !important;
  height: 162px !important; 
}
#root > div > div > div > div > div.dashboardcontent.px-2 > div.container-fuild.pb-4.pt-3.px-3.bg-white > form > div.row.bg-white.pb-4.round > div:nth-child(12) > div > div.col-md-6 > div > div:nth-child(3) > div.border.round.py-3.d-flex.justify-content-center > label > div > img{
  width: 140px !important;
  height: 162px !important;
}
#root > div > div > div > div > div.dashboardcontent.px-2 > div.container-fuild.pb-4.pt-3.px-2.bg-white > form > div.row.bg-white.pb-4.round > div:nth-child(12) > div > div:nth-child(2) > div > div:nth-child(2) > div.border.round.py-3.d-flex.justify-content-center > label > div > img{
  width: 140px !important;
  height: 162px !important;
}
#root > div > div > div > div > div.dashboardcontent.px-2 > div.container-fuild.pb-4.pt-3.px-2.bg-white > form > div > div:nth-child(15) > div > div:nth-child(8) > div.row.px-2.pt-3 > div:nth-child(2) > div > div:nth-child(2) > div.border.round.py-3.d-flex.justify-content-center > label > div > img{
  width: 140px !important;
  height: 162px !important;
}
#root > div > div > div > div > div.dashboardcontent.px-2 > form > div > div.row.bg-white.pb-4.round > div:nth-child(14) > div > div:nth-child(2) > div > div:nth-child(2) > div > div > img{
  width: 140px !important;
  height: 162px !important;
}
#root > div > div > div > div > div.dashboardcontent.px-2 > div.container-fuild.pb-4.pt-3.px-2.bg-white > div > div:nth-child(14) > div > div:nth-child(2) > div > div:nth-child(2) > div > div > img{
  width: 140px !important;
  height: 162px !important;
}
#root > div > div > div > div > div.dashboardcontent.px-2 > form > div > div.row.bg-white.pb-4.round > div:nth-child(15) > div > div:nth-child(8) > div > div:nth-child(2) > div > div:nth-child(2) > div > div > img{
  width: 140px !important;
  height: 162px !important;
}
#root > div > div > div > div > div.dashboardcontent.px-2 > form > div > div.row.bg-white.pb-4.round > div:nth-child(14) > div > div:nth-child(2) > div > div:nth-child(2) > div > div > img{
  width: 140px !important;
  height: 162px !important;
}
#root > div > div > div > div > div.dashboardcontent.px-2 > form > div > div.row.bg-white.pb-4.round > div:nth-child(15) > div > div:nth-child(2) > div > div:nth-child(2) > div > div > img{
  width: 140px !important;
  height: 162px !important;
}
#root > div > div > div > div > div.dashboardcontent.px-2 > div.container-fuild.px-2.pb-4.pt-4.bg-white > div > div:nth-child(15) > div > div:nth-child(2) > div > div:nth-child(2) > div > div > img{
  width: 140px !important;
  height: 162px !important;
}
#root > div > div > div > div > div.dashboardcontent.px-2 > div.container-fuild.pb-4.pt-3.px-2.bg-white > form:nth-child(3) > div > div:nth-child(14) > div > div:nth-child(2) > div > div:nth-child(2) > div > div > img{
  width: 140px !important;
  height: 162px !important;
}
#root > div > div > div > div > div.dashboardcontent.px-2 > div.container-fuild.pb-4.pt-3.px-2.bg-white > div > div:nth-child(18) > div > div:nth-child(2) > div > div:nth-child(2) > div > div > img{
  width: 140px !important;
  height: 162px !important;
}
#root > div > div > div > div > div.dashboardcontent.px-2 > div.container-fuild.pb-4.pt-3.px-2.bg-white > form > div > div:nth-child(14) > div > div:nth-child(2) > div > div:nth-child(2) > div > div > img{
  width: 140px !important;
  height: 162px !important;
}

#root > div > div > div > div > div.dashboardcontent.px-2 > div.container-fuild.pb-4.pt-3.px-2.bg-white > div > div:nth-child(18) > div > div:nth-child(2) > div > div:nth-child(2) > div > div > img{
  width: 140px !important;
  height: 162px !important;
}

#root > div > div > div > div > div.dashboardcontent.px-2 > div.container-fuild.pb-4.pt-3.px-2.bg-white > div > div:nth-child(15) > div > div:nth-child(12) > div > div:nth-child(2) > div > div:nth-child(2) > div > div > img{
  width: 140px !important;
  height: 162px !important;
}

#root > div > div > div > div > div.dashboardcontent.px-2 > div.container-fuild.pb-4.pt-3.px-2.bg-white > div > div:nth-child(14) > div > div:nth-child(2) > div > div:nth-child(2) > div > div > img{
  width: 140px !important;
  height: 162px !important;
}
#root > div > div > div > div > div.dashboardcontent.px-2 > div.container-fuild.pb-4.pt-3.px-3.bg-white > form > div.row.bg-white.pb-4.round > div:nth-child(7) > div > div.col-lg-9 > div > input{
  border: none;
  outline: none;
}
/* #root > div > div > div > div > div.dashboardcontent.px-2 > div.container-fuild.pb-4.pt-3.px-2.bg-white > form > div.row.bg-white.pb-4.round > div:nth-child(8) > div > div.col-lg-9 > div > input{
  border: none;
  outline: none;
} */
#root > div > div > div > div > div.dashboardcontent.px-2 > div.container-fuild.pb-4.pt-3.px-2.bg-white > form > div.row.bg-white.pb-4.round > div:nth-child(7) > div > div.col-lg-9 > div > input{
  border: none;
  outline: none;
}
/* #root > div > div > div > div > div.dashboardcontent.px-2 > div.container-fuild.pb-4.pt-3.px-2.bg-white > form > div > div:nth-child(4) > div > div.col-lg-9 > div > input{
  border: none;
  outline: none;
} */
#root > div > div > div > div > div.dashboardcontent.px-2 > div.container-fuild.pb-4.pt-3.px-2.bg-white > div > div.col-12 > div > div:nth-child(2) > div > div:nth-child(2) > div > div > img{
  width: 140px !important;
  height: 162px !important;
}
.dark-light:checked+label {
  transform: rotate(360deg);
}
#root > div > div > div > div > div.dashboardcontent.px-2 > div.container-fuild.pb-4.pt-3.px-2.bg-white > form > div.row.bg-white.pb-4.round > div:nth-child(13) > div > div:nth-child(2) > div > div:nth-child(2) > div.border.round.py-3.d-flex.justify-content-center > label > div > img{
  width: 140px !important;
  height: 162px !important;
}
#root > div > div > div > div > div.dashboardcontent.px-2 > div.container-fuild.pb-4.pt-3.px-2.bg-white > div > div:nth-child(17) > div > div:nth-child(11) > div > div:nth-child(2) > div > div:nth-child(2) > div > div > img{
  width: 140px !important;
  height: 162px !important;
}
#root > div > div > div > div > div.dashboardcontent.px-2 > div.container-fuild.pb-4.pt-3.px-2.bg-white > form > div > div:nth-child(15) > div > div:nth-child(2) > div > div:nth-child(2) > div > div > img{
  width: 140px !important;
  height: 162px !important;
}
#root > div > div > div > div > div.dashboardcontent.px-2 > div.container-fuild.pb-4.pt-3.px-2.bg-white > form > div > div:nth-child(16) > div > div:nth-child(7) > div.row.px-2.pt-3 > div:nth-child(2) > div > div:nth-child(2) > div.border.round.py-3.d-flex.justify-content-center > label > div > img{
  width: 140px !important;
  height: 162px !important;
}

.dark-light:checked+label:after,
.dark-light:not(:checked)+label:after {
  position: absolute;
  content: '';
  top: 1px;
  left: 1px;
  overflow: hidden;
  z-index: 2;
  display: block;
  border-radius: 50%;
  width: 44px;
  height: 44px;
  background-size: 20px 20px;
  background-repeat: no-repeat;
  background-position: center;
  transition: all 200ms linear;
  opacity: 0;
}

.dark-light:checked+label:after {
  opacity: 1;
}

.light-back {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  /* background-color: #fff; */
  overflow: hidden;
  background-position: center;
  background-repeat: repeat;
  background-size: 4%;
  height: 100%;
  width: 100%;
  transition: all 200ms linear;
  opacity: 0;
}

.light-back {
  opacity: 1;
}

.dropdown:checked+label,
.dropdown:not(:checked)+label {
  position: relative;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 15px;
  line-height: 2;
  height: 55px;
  transition: all 200ms linear;
  border-radius: 4px;
  width: 135px;
  /* letter-spacing: 1px; */
  display: inline-flex;
  background-color: #0f66b9;
  align-items: center;
  justify-content: center;
  -ms-flex-pack: center;
  text-align: center;
  border: none;
  /* background-color: #2da122; */
  cursor: pointer;
  color: #fff;
  box-shadow: 0 12px 35px 0 rgba(255, 235, 167, .15);
}

.sec-center .for-dropdown {

  background-color: #102770;
  color: #ffeba7;
  box-shadow: 0 12px 35px 0 rgba(16, 39, 112, .25);
}

.dropdown:checked+label:before,
.dropdown:not(:checked)+label:before {
  position: fixed;
  top: 0;
  left: 0;
  content: '';
  width: 100%;
  height: 100%;
  z-index: -1;
  cursor: auto;
  pointer-events: none;
}

.dropdown:checked+label:before {
  pointer-events: auto;
}

.dropdown:not(:checked)+label .uil {
  font-size: 24px;
  margin-left: 10px;
  transition: transform 200ms linear;
}

.dropdown:checked+label .uil {
  transform: rotate(180deg);
  font-size: 24px;
  margin-left: 10px;
  transition: transform 200ms linear;
}

.section-dropdown {
  position: absolute;
  padding: 5px;
  /* background-color: #111; */
  top: 70px;
  left: 0;
  width: 100%;
  border-radius: 4px;
  display: block;
  box-shadow: 0 14px 35px 0 rgba(9, 9, 12, 0.4);
  z-index: 2;
  opacity: 0;
  pointer-events: none;
  transform: translateY(20px);
  transition: all 200ms linear;
}

.sec-center .section-dropdown {
  width: 16vw;
  margin-left: -34px;
  background-color: #fff;
  box-shadow: 0 14px 35px 0 rgba(9, 9, 12, 0.15);
}

.dropdown:checked~.section-dropdown {
  opacity: 1;
  pointer-events: auto;
  transform: translateY(0);
}

.section-dropdown:before {
  position: absolute;
  top: -20px;
  left: 0;
  width: 100%;
  height: 20px;
  content: '';
  display: block;
  z-index: 1;
}

.section-dropdown:after {
  position: absolute;
  top: -7px;
  left: 30px;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #111;
  content: '';
  display: block;
  z-index: 2;
  transition: all 200ms linear;
}

.sec-center .section-dropdown:after {
  border-bottom: 8px solid #fff;
}

.sec-center a {
  position: relative;
  color: #fff;
  transition: all 200ms linear;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 15px;
  border-radius: 2px;
  padding: 5px 10px;
  /* padding-left: 20px;
  padding-right: 15px; */
  margin: 2px 0;
  text-align: left;
  text-decoration: none;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  justify-content: space-between;
  -ms-flex-pack: distribute;
}

.section-dropdown a {
  color: #102770;
}

.sec-center a:hover {
  color: #102770;
  background-color: #ffeba7;
}

.sec-center .section-dropdown a:hover {
  color: white;
  background-color: #0f66b9;
}

.sec-center a .uil {
  font-size: 22px;
}

.dropdown-sub:checked+label,
.dropdown-sub:not(:checked)+label {
  position: relative;
  color: #fff;
  transition: all 200ms linear;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 15px;
  border-radius: 2px;
  padding: 5px 0;
  padding-left: 20px;
  padding-right: 15px;
  text-align: left;
  text-decoration: none;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  justify-content: space-between;
  -ms-flex-pack: distribute;
  cursor: pointer;
}

.dropdown-sub:checked+label .uil,
.dropdown-sub:not(:checked)+label .uil {
  font-size: 22px;
}

.dropdown-sub:not(:checked)+label .uil {
  transition: transform 200ms linear;
}

.dropdown-sub:checked+label .uil {
  transform: rotate(135deg);
  transition: transform 200ms linear;
}

.dropdown-sub:checked+label:hover,
.dropdown-sub:not(:checked)+label:hover {
  color: #102770;
  background-color: #ffeba7;
}

.sec-center .section-dropdown .for-dropdown-sub {
  color: #102770;
}

.sec-center .section-dropdown .for-dropdown-sub:hover {
  color: #ffeba7;
  background-color: #102770;
}

.section-dropdown-sub {
  position: relative;
  display: block;
  width: 100%;
  pointer-events: none;
  opacity: 0;
  max-height: 0;
  padding-left: 10px;
  padding-right: 3px;
  overflow: hidden;
  transition: all 200ms linear;
}

.dropdown-sub:checked~.section-dropdown-sub {
  pointer-events: auto;
  opacity: 1;
  max-height: 999px;
}

.section-dropdown-sub a {
  font-size: 14px;
}

.section-dropdown-sub a .uil {
  font-size: 20px;
}

.logo {
  position: fixed;
  top: 50px;
  left: 40px;
  display: block;
  z-index: 11000000;
  background-color: transparent;
  border-radius: 0;
  padding: 0;
  transition: all 250ms linear;
}

.logo:hover {
  background-color: transparent;
}

.logo img {
  height: 26px;
  width: auto;
  display: block;
  transition: all 200ms linear;
}

.logo img {
  filter: brightness(10%);
}

.mod-line {
  border-top: none !important;
  border-bottom: none !important;
}

.account {
  color: #0C5398;
}

.acc {
  font-size: 12px;
  color: #0C5398;
}

p {
  margin: 0px;
  padding: 0px;
}

.head-txt {
  margin: auto;
  font-size: 14px;
  font-weight: 700;
}

.head-para {
  font-size: 12px;
}

.sec-center ul li a span {
  margin-right: auto;
  padding-left: 13px;
}

.dropdown-toggle::after {

  display: none !important;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-menu {
  position: absolute;
  width: 25vw;
  inset: 0px auto auto 0px;
  margin: 0px;
  padding: 8px 10px;
  transform: translate(-200px, 40px) !important;
}

.head {
  position: absolute;
  width: 25vw;
  inset: 0px auto auto 0px;
  margin: 0px;
  padding: 8px 10px;
  /* transform: translate(-200px, 40px) !important; */
}

body {
  background-color: #E0E0E0 !important;
}

.app {
  width: 100%;
  background-color: #f8f8f8 ;
}

._width_57 {
  width: 46%;
}

#root>div>div>div>div.dashboardcontent.px-lg-4.px-1>div.container.py-4 {
  padding: 0px;
}

#root>div>div>div>div.dashboardcontent.px-4>div.container.py-4>div.row.mt-4.mx-1>div.col-7.dbox>div>div.col-12.py-3>div>div>div>div {
  margin: 0px;
  padding: 10px;
  background: linear-gradient(to right, rgb(12, 83, 152), rgb(18, 113, 205));
  border: none !important;
  white-space: nowrap;
  outline: none;
}

.sidebarcum {
  background-color: #fff;
}

.btn-check:checked+.btn,
:not(.btn-check)+.btn:active,
.btn:first-child:active,
.btn.active,
.btn.show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  /* border-color: var(--bs-btn-active-border-color); */
}
.centerlo{
  position: relative;
}

.special div {
  font-size: 16px !important;
  font-weight: 600 !important;
  color: #2F2F2F !important;
}

.notification {
  display: flex;
  padding: 0px 11px;
}

.header .noti {
  font-size: 12px;
}

.dbox {
  background-color: #f8f8f8  !important;
  padding: 0px 25px !important;
}
.specailshow{
  filter: drop-shadow(0 2px 13px rgba(0, 0, 0, 0.2));
}
.dbox>div {
  filter: drop-shadow(0 2px 13px rgba(0, 0, 0, 0.2));
}

.dbold {
  margin: 2px;
  font-size: 22px;
  font-weight: 700;
  line-height: 26.63px;
}

.pbold {
  font-size: 15px;
  font-weight: 600;
  color: #5F5F5F;
  margin: 0px !important;
}

.underline {
  border: 1px solid #D8D8D8;
}

.dtext {
  font-size: 18px;
  font-weight: 700;
  color: #243665;
}

.custselect {
  background: #EEEEEE !important;
  font-weight: 500 !important;
}

.headtb {
  display: flex;
  border-bottom: 1px solid #F3F3F3;
  justify-content: space-between;
  padding: 7px 0px;
}

.headtb>div {
  /* border-bottom: 1px solid #F3F3F3; */
}

.headtb div {
  display: flex;
  justify-content: space-between;
  padding: 5px 0px;
  color: #838282;
}

.topsidebar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logobtn {
  /* padding: 0px 10px; */
  color: var(--primary);
  font-size: 23px;
}

.logo {
  padding: 0px 14px;
}

.nothover {
  background-color: #fff;
}

.abc:hover {
  background-color: #fff !important;
}


#root>div>div>aside>div>div>nav.ps-menu-root.nothover.abc.css-vj11vy>ul>li>a {
  padding-right: 0px !important;
}

.header {
  width: 100%;
  display: flex;
  background: linear-gradient(to right, rgba(12, 83, 152, 1), rgba(18, 113, 205, 1));
  justify-content: flex-end;
  align-items: center;
  gap: 13px;
  padding: 0px 46px;
}

.icongroup {
  display: flex;
  align-items: center;
  gap: 13px;
  padding: 0px 19px;
}

.userlogo {
  display: flex;
  padding: 5px 0px;
}

.white {
  color: #fff;
}

.usertext {
  font-weight: 600;
  font-size: 16px;
}

.dashboardcontent {
  padding: 8px 12px;
}

.ccolor {
  font-weight: 700;
  font-size: 25px;
  line-height: 30px;
  color: var(--primary);
}

.fja {
  justify-content: flex-start;
  align-items: center;
  gap: 13px;
}

.customwid {
  width: 69% !important;
}

.customw {
  width: 124px !important;
}

.btn1 {
  border: 1.4px solid var(--primary);
  padding: 4px 26px;
  background-color: #fff;
  border-radius: 17px;
  font-weight: 600;
  color: var(--primary);
}

.btn2 {
  padding: 4px 20px;
  border: 0px;
  background-color: #15B611;
  border-radius: 17px;
  font-weight: 600;
  color: white;
}

.nav {
  color: #000;
  text-decoration: none;
}

.reset-card {
  display: flex;
  background: linear-gradient(to top,
      white 0%,
      white 50%,
      #0C5398 50%,
      #0C5398 100%);
}

.btn3 {
  padding: 4px 20px;
  border: 0px;
  background-color: #aec9df;
  border-radius: 10px;
  font-weight: 600;
  color: #0C5398;
}

.btn4 {
  padding: 4px 20px;
  border: 0px;
  background-color: #C0C0C0;
  border-radius: 18px;
  font-weight: 600;
  color: #4B4B4B;
}

.btn5 {
  padding: 4px 20px;
  border: 0px;
  background-color: #EB011C;
  border-radius: 18px;
  font-weight: 600;
  color: white;
}

.superpadding{
  padding: 20px 34px;
}

.btn5withoutradius {
  padding: 4px 20px;
  border: 0px;
  background-color: #EB011C;
  font-weight: 600;
  border-radius: 4px;
  color: white;
}
.btn5withoutradius002 {
width: 100%;
  border: 0px;
  padding: 8px 12px;
  background-color: rgba(36, 54, 101, 1);
  font-weight: 600;
  border-radius: 4px;
  color: white;
}

.btn6 {
  padding: 4px 20px;
  background-color: #fff;
  font-weight: 600;
  color: #0C5398;
  border: 2px solid gainsboro;
  border-radius: 0.4em;
  box-shadow: 0.1em;
}

.btn7 {
  padding: 10px 20px;
  border: 0px;
  background-color: #EB011C;
  border-radius: 8px;
  font-weight: 600;
  color: white;
  margin: auto;
  width: 82%;
}

.btn8 {
  padding: 10px 20px;
  border: 0px;
  background-color: #EB011C;
  border-radius: 8px;
  font-weight: 600;
  color: white;
  margin: auto;
  width: 100%;
}

.closecancel {
  background: #C0C0C0 !important;
  color: #4B4B4B;
}

.closebtn {
  background: #0C5398 !important;
}

.ccedit-h {
  color: #0C5398;
  font-size: 30px;
  font-weight: 700;
  text-align: center;
}

.ccedit-p {
  color: #5F5F5F;
  font-size: 15px;
  font-weight: 700;
  text-align: center;
}

.question-logo {
  /* font-size: 55px; */
  font-size: 40px;
  color: #ec642a;
  background-color: white;
  border-radius: 50%;
  padding: 15px;
  height: 80px;
  width: 16%;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  margin: -34px auto -52px auto;
}

.pagination {
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.375rem;
  --bs-pagination-font-size: 1rem;
  --bs-pagination-color: black !important;
  --bs-pagination-bg: #fff;
  --bs-pagination-border-width: 1px;
  --bs-pagination-border-color: none !important;
  --bs-pagination-border-radius: 0.375rem;
  --bs-pagination-hover-color: #fff !important;
  --bs-pagination-hover-bg: #d62b2b !important;
  --bs-pagination-hover-border-color: #dee2e6;
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: #e9ecef;
  --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: #0d6efd;
  --bs-pagination-active-border-color: #0d6efd;
  --bs-pagination-disabled-color: #6c757d;
  --bs-pagination-disabled-bg: #fff;
  --bs-pagination-disabled-border-color: #dee2e6;
  display: flex;
  padding-left: 0;
  list-style: none;
}

.customcolor2 {
  background-color: #EB011C !important;
  color: #fff;
}

.customfont {
  font-size: 20px;
  font-weight: 700;
  color: #0C5398;
}

th {
  font-size: 14px !important;
}

td {
  font-size: 14px !important;
}

.desgin1 {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  /* border: 1px solid black; */
  overflow: hidden;
}

.customtext23 {
  font-size: 20px;
  font-weight: 700;
}

.customcolor {
  color: #838282;
}

#root>div>div>aside>div {
  background-color: #fff;
}

.active>li {
  background-color: rgba(12, 82, 151, 0.2) !important;
  color: rgb(12, 83, 152);
  border-right: 4px solid #0C5398;
}

.created {
  padding: 2px 16px !important;
  border-radius: 69px !important;
  font-size: 14px !important;
  background: #B4DAFF !important;
  border: 1px solid white !important;
  color: #0070DC !important;
  font-weight: 800 !important;
}

.checked {
  padding: 2px 14px !important;
  border-radius: 69px !important;
  font-size: 14px !important;
  background: rgba(110, 255, 107, 0.49) !important;
  border: 1px solid white !important;
  color: #05A501 !important;
  font-weight: 800 !important;
}

.approved {
  padding: 2px 11px !important;
  border-radius: 69px !important;
  font-size: 14px !important;
  background: rgba(255, 196, 107, 0.49) !important;
  border: 1px solid white !important;
  color: #D49902 !important;
  font-weight: 800 !important;
}

.custombtn12 {
  padding: 2px 13px !important;
  border-radius: 69px !important;
  font-size: 14px !important;
}

#root>div>div>aside>div>div>nav.ps-menu-root.nothover.abc.css-vj11vy>ul>li>a:hover {
  background-color: #fff !important;
}

.thumcontent {
  padding: 8px;
  width: fit-content;
}

.loginpage {
  min-height: 100vh;
  background-color: #F3F6FA !important;
}

.custombtn121 {
  padding: 2px 9px !important;
}

.custombtn122 {
  padding: 2px 14px !important;
}

.overlapimg {
  position: relative;
  background-image: url('../src/assets/back.png');
  background-size: 100% 100%;
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.overlapimg>img {
  position: relative;
  right: 77px;
}

.lp-left {
  /* width: 55%; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.lp-right {
  /* width: 45%; */
}

.welcomeback {
  font-size: 33px;
  font-weight: 400;
  color: #243665;
  margin: 0px;
  line-height: 40.23px;
}

.welcomeback2 {
  font-size: 2.5rem;
  font-weight: 600;
  color: #243665;
  margin: 0px;
  line-height: 48.76px;
}

.reset-head {
  text-align: center;
  color: #243665;
  font-weight: 600;
  margin: 15px;
  font-size: 23px;
}

.reset-p {
  text-align: center;
  color: grey;
  font-weight: 600;
}

.customheight {
  height: 49px !important;
}

.forgetinput {
  height: 49px !important;
  border: none !important;
  border-bottom: 1px solid gray !important;
}

.customdesgi {
  font-size: 15px;
  color: #0C5398;
  font-weight: 700;
}

.css-16jkw2k>.ps-menu-button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 55px !important;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  box-sizing: border-box;
  cursor: pointer;
  padding-right: 20px;
  padding-left: 20px;
}

/* notification */



/* notification end */


@media screen and (max-width: 1200px) {
  .welcomeback {
    font-size: 30px;
    font-weight: 400;
    color: #243665;
    margin: 0px;
    line-height: 40.23px;
  }

  .center .pop-label {
    position: absolute;
    width: 100%;
    top: 58px;
    right: -99px;
    transform: rotate(41deg);
    background-color: #ff0c00;
    color: #ffffff;
    padding: 1px 10px;
    font-weight: bold;
    font-size: 14px;
}

  .spaceofx{
    width: 80% !important;
  }

  .desc23{
    font-size: 20px;
    line-height: 24px;
  }

  .welcomeback2 {
    font-size: 2.2rem;
    font-weight: 600;
    color: #243665;
    margin: 0px;
    line-height: 48.76px;
  }

  /* .section-dropdown{

  } */
  .sec-center .section-dropdown {
    width: 22vw;
    margin-left: -36px;
    background-color: #fff;
  
  }
  #exampleModaltwo > .modal-lg, .modal-xl {
    --bs-modal-width: 1266px !important;
}
}

/* @media screen and (max-width: 1156px) {
  .pop-label {
    position: absolute;
    width: 100%;
    top: 42px;
    right: -91px;
    transform: rotate(41deg);
    background-color: #ff0c00;
    color: #ffffff;
    padding: 1px 10px;
    font-weight: bold;
    font-size: 14px;
}
} */

@media screen and (max-width: 1100px) {


  .center .pop-label {
    position: absolute;
    width: 100%;
    top: 58px;
    right: -94px;
    transform: rotate(41deg);
    background-color: #ff0c00;
    color: #ffffff;
    padding: 1px 10px;
    font-weight: bold;
    font-size: 14px;
}
  .section-dropdown img {
    height: 34px;
  }

  .head-para {
    font-size: 12px;
  }
  .spaceofx{
    width: 80% !important;
  }
  #exampleModaltwo > .modal-lg, .modal-xl {
    --bs-modal-width: 1266px !important;
}
}
/* @media screen and (max-width: 1050px) {
  .pop-label {
    position: absolute;
    width: 100%;
    top: 41px;
    right: -80px;
    transform: rotate(41deg);
    background-color: #ff0c00;
    color: #ffffff;
    padding: 1px 10px;
    font-weight: bold;
    font-size: 14px;
}
} */
@media screen and (max-width: 994px) {
  .section-dropdown img {
    height: 34px;
  }


  .newjc{
    justify-content: start;
  }


  .react-overlay {
    background-color: rgba(47, 44, 44, 0.5);
    overflow: hidden;
    opacity: 1.8;
    padding: 40px 10px;
    height: 358px;
}
  .center .pop-label {
    position: absolute;
    width: 100%;
    top: 58px;
    right: -85px;
    transform: rotate(41deg);
    background-color: #ff0c00;
    color: #ffffff;
    padding: 1px 10px;
    font-weight: bold;
    font-size: 14px;
}
.footwidth{
  width: 80%;
}
  .head-para {
    font-size: 12px;
  }

  .spaceofx{
    width: 80% !important;
  }

  .dropdown-menu {
    position: absolute;
    width: 40vw !important;
    inset: 0px auto auto 0px;
    margin: 0px;
    padding: 8px 10px;
    transform: translate(-200px, 40px) !important;
}

  .welcomeback {
    font-size: 30px;
    font-weight: 400;
    color: #243665;
    margin: 0px;
    line-height: 40.23px;
  }

  .welcomeback2 {
    font-size: 2.2rem;
    font-weight: 600;
    color: #243665;
    margin: 0px;
    line-height: 48.76px;
  }

  .isnone {
    display: none;
  }
}
@media screen and (max-width: 922px) {
  .center .pop-label {
    position: absolute;
    width: 100%;
    top: 69px;
    right: -99px;
    transform: rotate(41deg);
    background-color: #ff0c00;
    color: #ffffff;
    padding: 1px 10px;
    font-weight: bold;
    font-size: 14px;
}
.imgsize123{
  width: 55%;
}

.newjc{
  justify-content: start;
}
}
@media screen and (max-width: 860px) {
  .center .pop-label {
    position: absolute;
    width: 100%;
    top: 66px;
    right: -90px;
    transform: rotate(41deg);
    background-color: #ff0c00;
    color: #ffffff;
    padding: 1px 10px;
    font-weight: bold;
    font-size: 14px;
}
.newjc{
  justify-content: start;
}
  .sec-center .section-dropdown {
    width: 22vw;
    margin-left: -36px;
    background-color: #fff;
  
  }
  .dataTables_filter{
    text-align: right !important;
  }
  .sec-center .sec-profile{
    margin-left: 0px !important;
  }
  .sec-center a {
   font-size: 14px;
  
  }
}
@media screen and (max-width: 800px) {
  .center .pop-label {
    position: absolute;
    width: 100%;
    top: 57px;
    right: -79px;
    transform: rotate(41deg);
    background-color: #ff0c00;
    color: #ffffff;
    padding: 1px 10px;
    font-weight: bold;
    font-size: 14px;
}

.newjc{
  justify-content: start;
}

.imgsize123{
  width: 58%;
}
}


@media screen and (max-width: 768px) {

  .stickylinks{
    display: none;
  }

  .newjc{
    justify-content: start;
  }

  .price-area span.duration23{
    display: block;
    font-size: 27px;
    font-weight: 600;
    /* text-transform: uppercase; */
    margin: 10px 0 0;
    text-align: center;
    color: red;
    letter-spacing: 1px;
    vertical-align: baseline;
    padding-right: 7px;
  }
  .normaltxt {
    font-weight: 500;
    color: red;
    font-size: 25px !important;
}

  .desc120{
    font-size: 20px;
    font-weight: 400;
    line-height: 33px;
    text-align: justify;
    color: rgba(75, 75, 75, 1);
  }

  .title120 {
    font-weight: 700;
    font-size: 25px;
    line-height: 40px;
    text-align: justify;
    color: rgba(12, 83, 152, 1);
}

.hideul{
  display: none !important;
}
  .sec-center .section-dropdown {
    width: 35vw;
    margin-left: -36px;
    background-color: #fff;
  
  }
  .footwidth{
    width: 76%;
  }

  h3 {
    font-size: 20px;
  }
  single-pricing{
    margin: 30px;
  }
  .forheading{
    font-size: 35px;
    font-weight: 600;
    line-height: 50px;
    color: white;
  }
  
  .fordesc{
    font-size: 25px;
    color: white;
    font-weight: 500;
    line-height: 36.57px;
  }
  .headline{
    color: #0C5398;
    font-weight: 800;
    font-size: 25px;
  }
  .head-text h3 {
    font-family: poppins;
    margin: 0;
    font-size: 16px;
    letter-spacing: 1px;
    font-weight: 700;
    text-transform: uppercase;
    color: #000;
  }

  .title2{
    font-size: 35px;
    font-weight: 500;
    line-height: 50px;
    color: #0C5398;
  }
  .backgroundbox{
    position: absolute;
    height: 100%;
    width: 100%;
    padding: 10px;
    background: linear-gradient(to bottom, rgb(12, 83, 152), rgb(18, 113, 205));
  }


  .dataTables_filter{
    text-align: right !important;
  }
  .sec-center .sec-profile{
    margin-left: 0px !important;
  }
  .sec-center a {
   font-size: 14px;
  
  }
  .dropdown-menu img{
    height: 34px;
  }
  .noti-h{
    font-size: 12px;
  }
  .noti{
    font-size: 9px !important;
  }


  .price-area {
    position: relative;
    padding: 10px 0;
    color: white;
    font-weight: 300;
    /* font-size: 40px; */
    line-height: 1;
    
  }
  
  .price-area span.duration {
    display: block;
    font-size: 25px;
    font-weight: 600;
    text-transform: uppercase;
    margin: 10px 0 0;
    text-align: center;
    color: white;
    letter-spacing: 1px;
    vertical-align: baseline;
    margin: 20px 0 0 3px;
  }
}
@media screen and (max-width: 700px) {
  .newjc{
    justify-content: start;
  }

  .page-title {
    font-size: 34px;
}
.react-overlay {
  background-color: rgba(47, 44, 44, 0.5);
  overflow: hidden;
  opacity: 1.8;
  padding: 8px 10px;
  height: 275px;
}
  .superpadding{
    padding: 10px 18px;
  }
  .center .pop-label {
    position: absolute;
    width: 100%;
    top: 51px;
    right: -68px;
    transform: rotate(41deg);
    background-color: #ff0c00;
    color: #ffffff;
    padding: 1px 10px;
    font-weight: bold;
    font-size: 14px;
}
}
@media screen and (max-width: 600px) {
  .sec-center .section-dropdown {
    width: 28vw;
    margin-left: -22px;
    background-color: #fff;
  
  }


  .btn-area {
    padding: 10px 20px;
  }
  
  .btn-area a {
    color: rgba(12, 83, 152, 1);
    display: inline-block;
    position: relative;
    cursor: pointer;
    outline: 0;
    white-space: nowrap;
    margin: 5px;
    padding: 0 18px;
    font-size: 14px;
    height: 40px;
    line-height: 40px;
    background-color:rgba(234, 234, 234, 1);
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
    border: none;
  }
  .price-area {
    position: relative;
    padding: 10px 0;
    color: red;
    font-weight: 300;
    font-size: 40px;
    line-height: 1;
    
  }
  
  .price-area span.duration {
    display: block;
    font-size: 23px;
    font-weight: 600;
    text-transform: uppercase;
    margin: 10px 0 0;
    text-align: center;
    color: #0070DC;
    letter-spacing: 1px;
    vertical-align: baseline;
    margin: 20px 0 0 3px;
  }
  .head-text h3 {
    font-family: poppins;
    margin: 0;
    font-size: 15px;
    letter-spacing: 1px;
    font-weight: 700;
    text-transform: uppercase;
    color: #000;
  }

  .spaceofx{
    width: 96% !important;
  }

  .page-title{
    font-size: 28px;
  }

  .react-overlay {
    background-color: rgba(47, 44, 44, 0.5);
    overflow: hidden;
    opacity: 1.8;
    padding: 10px 10px;
    height: 248px;
}
  
  .bann-txt{
    color: #fff;
    text-align: center;
    font-weight: 800;
    letter-spacing: 3px;
  }




  .imgsize{
    width: 44%;
  }

  .imgsize123{
    width: 44%;
  }
  .imgdivsize{
    width: 80% !important;
  }
  .dataTables_filter{
    text-align: right !important;
  }
  .sec-center .sec-profile{
    margin-left: 0px !important;
  }
  .sec-center a {
   font-size: 12px;
  
  }
  .dropdown-menu {
    position: absolute;
    width: 25vw;
    inset: 0px auto auto 10px;
    margin-left: 60px !important;
    padding: 8px 10px;
  }
  .dropdown-menu .drop-msg{
    margin-left: 10px !important;
  }
  
}
@media screen and (max-width: 500px) {
  .sec-center .section-dropdown {
    width: 31vw;
    margin-left: -22px;
    background-color: #fff;
  
  }

  .desc120 {
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    text-align: justify;
    color: rgba(75, 75, 75, 1);
}

.title120 {
  font-weight: 700;
  font-size: 21px;
  line-height: 40px;
  text-align: justify;
  color: rgba(12, 83, 152, 1);
}
  .forheading{
    font-size: 30px;
    font-weight: 600;
    line-height: 40px;
    color: white;
  }
  
  .fordesc{
    font-size: 20px;
    color: white;
    font-weight: 500;
    line-height: 26.57px;
  }
  .title2{
    font-size: 30px;
    font-weight: 600;
    line-height: 40px;
    color: #0C5398;
  }
  .sec-center .sec-profile{
    margin-left: 0px !important;
    display: block !important;
  }
  .sec-center .name-drop{
    margin: auto;
  }
  .sec-center a {
   font-size: 12px;
  
  }

  .spaceofx{
    width: 100% !important;
  }


  .page-title {
    font-size: 23px;
}
.react-overlay {
  background-color: rgba(47, 44, 44, 0.5);
  overflow: hidden;
  opacity: 1.8;
  padding: 8px 10px;
  height: 200px;
}
}

@media screen and (max-width: 464px) {
  .sec-center .section-dropdown {
    width: 46vw;
    margin-left: -22px;
    background-color: #fff;
  
  }


  .page-title {
    font-size: 21px;
}


.react-overlay {
  background-color: rgba(47, 44, 44, 0.5);
  overflow: hidden;
  opacity: 1.8;
  padding: 2px 10px;
  height: 178px;
}

#root > div > div > div > section:nth-child(1) > nav > div > button{
  margin: 4px 0px;
}
  .center .pop-label {
    position: absolute;
    width: 100%;
    top: 81px;
    right: -103px;
    transform: rotate(41deg);
    background-color: #ff0c00;
    color: #ffffff;
    padding: 1px 10px;
    font-weight: bold;
    font-size: 14px;
}
  /* .page-title{
    font-size: 32px;
  } */
  
  .bann-txt{
    color: #fff;
    text-align: center;
    font-weight: 800;
    letter-spacing: 3px;
  }

  .imgsize{
    width: 48%;
  }
  .imgdivsize{
    width: 80% !important;
  }
  .sec-center .sec-profile{
    margin-left: 0px !important;
    display: block !important;
  }
  .sec-center a {
   font-size: 12px;
  
  }
  .welcomeback {
    font-size: 27px;
    font-weight: 400;
    color: #243665;
    margin: 0px;
    line-height: 40.23px;
  }

  .welcomeback2 {
    font-size: 1.7rem;
    font-weight: 600;
    color: #243665;
    margin: 0px;
    line-height: 48.76px;
  }

  .isnone {
    display: none;
  }
  .spaceofx{
    width: 100% !important;
  }
}
@media screen and (max-width: 380px) {
  .sec-center .section-dropdown {
    width: 40vw;
    margin-left: -18px;
    background-color: #fff;
  
  }

  .center .pop-label {
    position: absolute;
    width: 100%;
    top: 67px;
    right: -82px;
    transform: rotate(41deg);
    background-color: #ff0c00;
    color: #ffffff;
    padding: 1px 10px;
    font-weight: bold;
    font-size: 14px;
}
  .sec-center .sec-profile{
    margin-left: 0px !important;
    display: block !important;
  }
  .spaceofx{
    width: 100% !important;
  }
  .sec-center a {
   font-size: 12px;
  
  }
}