/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */


/* special for contact form */

#root > div > div > div > section.superpadding > form > div > div > div.col-md-7.col-12 > div > div:nth-child(4) > div > div:nth-child(2) > div{
  display: flex;
  align-items: center;
}



#root > div > div > div > section.superpadding > form > div > div > div.col-md-7.col-12 > div > div:nth-child(4) > div > div:nth-child(2) > div > div{
  position: relative;
  align-self: stretch;
  display: flex;
  align-items: center;
  margin-right: var(--PhoneInputCountrySelect-marginRight);
}


#root > div > div > div > section.superpadding > form > div > div > div.col-md-7.col-12 > div > div:nth-child(4) > div > div:nth-child(2) > div > div > select{
  position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    border: 0;
    opacity: 0;
    cursor: pointer;
}



#root > div > div > div > section.superpadding > form > div > div > div.col-md-7.col-12 > div > div:nth-child(4) > div > div:nth-child(2) > div > div > div.PhoneInputCountryIcon.PhoneInputCountryIcon--border{
  width: calc(var(--PhoneInputCountryFlag-height) * var(--PhoneInputCountryFlag-aspectRatio));
  height: var(--PhoneInputCountryFlag-height);
}


#root > div > div > div > section.superpadding > form > div > div > div.col-md-7.col-12 > div > div:nth-child(4) > div > div:nth-child(2) > div > div > div.PhoneInputCountryIcon.PhoneInputCountryIcon--border > img{
  width: 30px;
}


#root > div > div > div > section.superpadding > form > div > div > div.col-md-7.col-12 > div > div:nth-child(4) > div > div:nth-child(2) > div > div > div.PhoneInputCountrySelectArrow{
  
  width: 12px;
  display: none;
  content: '';
  width: var(--PhoneInputCountrySelectArrow-width);
  height: var(--PhoneInputCountrySelectArrow-width);
  margin-left: var(--PhoneInputCountrySelectArrow-marginLeft);
  border-style: solid;
  border-color: var(--PhoneInputCountrySelectArrow-color);
  border-top-width: 0;
  border-bottom-width: var(--PhoneInputCountrySelectArrow-borderWidth);
  border-left-width: 0;
  border-right-width: var(--PhoneInputCountrySelectArrow-borderWidth);
  transform: var(--PhoneInputCountrySelectArrow-transform);
  opacity: var(--PhoneInputCountrySelectArrow-opacity);

}



#root > div > div > div > section.superpadding > form > div > div > div.col-md-7.col-12 > div > div:nth-child(4) > div > div:nth-child(2) > div > input{
  border: none !important;
  outline: none !important;
}


#root > div > div > div > section.superpadding > form > div > div > div.col-md-7.col-12 > div > div:nth-child(4) > div > div:nth-child(2) > div > div > div.PhoneInputCountryIcon > svg{
  width: 29px;
}


#root > div > div > div > section.superpadding > form > div > div > div.col-md-7.col-12 > div > div:nth-child(4) > div > div:nth-child(2) > div > div{
  padding: 0px 3px;
}